exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-centre-of-innovation-tsx": () => import("./../../../src/pages/centre-of-innovation.tsx" /* webpackChunkName: "component---src-pages-centre-of-innovation-tsx" */),
  "component---src-pages-contact-preferences-tsx": () => import("./../../../src/pages/contact-preferences.tsx" /* webpackChunkName: "component---src-pages-contact-preferences-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-donate-now-tsx": () => import("./../../../src/pages/donate-now.tsx" /* webpackChunkName: "component---src-pages-donate-now-tsx" */),
  "component---src-pages-frequently-asked-questions-tsx": () => import("./../../../src/pages/frequently-asked-questions.tsx" /* webpackChunkName: "component---src-pages-frequently-asked-questions-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-legacies-tsx": () => import("./../../../src/pages/legacies.tsx" /* webpackChunkName: "component---src-pages-legacies-tsx" */),
  "component---src-pages-my-inchmarlo-tsx": () => import("./../../../src/pages/my-inchmarlo.tsx" /* webpackChunkName: "component---src-pages-my-inchmarlo-tsx" */),
  "component---src-pages-pledge-campaign-tsx": () => import("./../../../src/pages/pledge-campaign.tsx" /* webpackChunkName: "component---src-pages-pledge-campaign-tsx" */),
  "component---src-pages-privacy-notice-tsx": () => import("./../../../src/pages/privacy-notice.tsx" /* webpackChunkName: "component---src-pages-privacy-notice-tsx" */),
  "component---src-pages-rbai-together-tsx": () => import("./../../../src/pages/rbai-together.tsx" /* webpackChunkName: "component---src-pages-rbai-together-tsx" */),
  "component---src-pages-recognising-your-gift-tsx": () => import("./../../../src/pages/recognising-your-gift.tsx" /* webpackChunkName: "component---src-pages-recognising-your-gift-tsx" */),
  "component---src-pages-robert-howard-tsx": () => import("./../../../src/pages/robert-howard.tsx" /* webpackChunkName: "component---src-pages-robert-howard-tsx" */),
  "component---src-pages-rugby-150-payment-tsx": () => import("./../../../src/pages/rugby-150-payment.tsx" /* webpackChunkName: "component---src-pages-rugby-150-payment-tsx" */),
  "component---src-pages-rugby-150-tsx": () => import("./../../../src/pages/rugby-150.tsx" /* webpackChunkName: "component---src-pages-rugby-150-tsx" */),
  "component---src-pages-school-projects-tsx": () => import("./../../../src/pages/school-projects.tsx" /* webpackChunkName: "component---src-pages-school-projects-tsx" */),
  "component---src-pages-science-department-renovation-tsx": () => import("./../../../src/pages/science-department-renovation.tsx" /* webpackChunkName: "component---src-pages-science-department-renovation-tsx" */),
  "component---src-pages-share-your-views-tsx": () => import("./../../../src/pages/share-your-views.tsx" /* webpackChunkName: "component---src-pages-share-your-views-tsx" */),
  "component---src-pages-social-mobility-tsx": () => import("./../../../src/pages/social-mobility.tsx" /* webpackChunkName: "component---src-pages-social-mobility-tsx" */),
  "component---src-pages-south-wing-development-tsx": () => import("./../../../src/pages/south-wing-development.tsx" /* webpackChunkName: "component---src-pages-south-wing-development-tsx" */),
  "component---src-pages-south-wing-faqs-tsx": () => import("./../../../src/pages/south-wing-faqs.tsx" /* webpackChunkName: "component---src-pages-south-wing-faqs-tsx" */),
  "component---src-pages-sporting-excellence-tsx": () => import("./../../../src/pages/sporting-excellence.tsx" /* webpackChunkName: "component---src-pages-sporting-excellence-tsx" */),
  "component---src-pages-tax-efficient-giving-tsx": () => import("./../../../src/pages/tax-efficient-giving.tsx" /* webpackChunkName: "component---src-pages-tax-efficient-giving-tsx" */),
  "component---src-pages-transforming-the-campus-tsx": () => import("./../../../src/pages/transforming-the-campus.tsx" /* webpackChunkName: "component---src-pages-transforming-the-campus-tsx" */),
  "component---src-pages-videos-test-tsx": () => import("./../../../src/pages/videos/test.tsx" /* webpackChunkName: "component---src-pages-videos-test-tsx" */),
  "component---src-pages-videos-video-1-tsx": () => import("./../../../src/pages/videos/video-1.tsx" /* webpackChunkName: "component---src-pages-videos-video-1-tsx" */),
  "component---src-pages-videos-video-10-tsx": () => import("./../../../src/pages/videos/video-10.tsx" /* webpackChunkName: "component---src-pages-videos-video-10-tsx" */),
  "component---src-pages-videos-video-2-tsx": () => import("./../../../src/pages/videos/video-2.tsx" /* webpackChunkName: "component---src-pages-videos-video-2-tsx" */),
  "component---src-pages-videos-video-3-tsx": () => import("./../../../src/pages/videos/video-3.tsx" /* webpackChunkName: "component---src-pages-videos-video-3-tsx" */),
  "component---src-pages-videos-video-4-tsx": () => import("./../../../src/pages/videos/video-4.tsx" /* webpackChunkName: "component---src-pages-videos-video-4-tsx" */),
  "component---src-pages-videos-video-5-tsx": () => import("./../../../src/pages/videos/video-5.tsx" /* webpackChunkName: "component---src-pages-videos-video-5-tsx" */),
  "component---src-pages-videos-video-6-tsx": () => import("./../../../src/pages/videos/video-6.tsx" /* webpackChunkName: "component---src-pages-videos-video-6-tsx" */),
  "component---src-pages-videos-video-7-tsx": () => import("./../../../src/pages/videos/video-7.tsx" /* webpackChunkName: "component---src-pages-videos-video-7-tsx" */),
  "component---src-pages-videos-video-8-tsx": () => import("./../../../src/pages/videos/video-8.tsx" /* webpackChunkName: "component---src-pages-videos-video-8-tsx" */),
  "component---src-pages-videos-video-9-tsx": () => import("./../../../src/pages/videos/video-9.tsx" /* webpackChunkName: "component---src-pages-videos-video-9-tsx" */)
}

